/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

// Third party CSS
import 'swiper/css'
import 'swiper/css/pagination'

// Components
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Interface
import { ServicesProps } from 'components/flex/Services/Shell'

const Gradient = styled.div`
  background: linear-gradient(302.67deg, #ee7202 40.6%, #71bee7 90.97%);
  padding-bottom: 6rem;

  & svg {
    opacity: 0.5;
    @media (max-width: 575px) {
      height: 40px;
    }
  }
`

const Content = styled(ParseContent)`
  flex: none;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    line-height: 45px;
    @media (min-width: 992px) {
      font-size: 40px;
    }
  }
`

const ServicesWrapper = styled.div`
  margin-top: -6rem !important;
`

const Service = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.2s ease-in-out;
  will-change: transform;

  & span {
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 16px;
  }

  &:hover {
    filter: drop-shadow(5px 5px 5px #ffffff);
  }
`

const Icon = styled(Plaatjie)`
  width: 90px;
  height: 90px;
  @media (max-width: 575px) {
    width: 50px;
    height: 50px;
  }
`

const SwiperContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.75rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;
  margin-top: -5rem !important;

  & .swiper {
    padding-right: 1.5rem;
  }
`

const Services: React.FC<ServicesProps> = ({ fields }) => {
  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])
  return (
    <section className="mb-5 pb-lg-5">
      <Gradient>
        <div className="container py-5">
          <div className="d-flex align-items-end justify-content-between">
            <Content content={fields.description} className="d-flex me-4" />
          </div>
        </div>
      </Gradient>
      <div className="container" ref={containerRef}>
        <ServicesWrapper className="row d-none d-lg-flex">
          {fields.services?.map((service, index) => (
            <AnimatePresence>
              <motion.div
                initial={{
                  x: '100%',
                  filter: 'drop-shadow(0px 4px 20px rgba(255, 255, 255, 0.3))',
                }}
                animate={{
                  x: [100 * index + 100, 0],
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.3))',
                }}
                className="col mb-4 mb-lg-0"
                key={index}
              >
                <NavLink to={service?.link?.url || '#'} className="h-100">
                  <Service className="d-flex flex-column align-items-center justify-content-between p-3 p-sm-4 h-100">
                    <Icon image={service?.icon} alt="" />
                    <span className="d-flex align-items-center text-center mt-sm-4">
                      {service?.link?.title}
                    </span>
                  </Service>
                </NavLink>
              </motion.div>
            </AnimatePresence>
          ))}
        </ServicesWrapper>
      </div>
      {marginLeft !== null && (
        <SwiperContainer
          className="container d-lg-none"
          marginLeft={marginLeft}
        >
          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.75,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
          >
            {fields.services?.map((service, index) => (
              <SwiperSlide key={index}>
                <NavLink to={service?.link?.url || '#'} className="h-100">
                  <Service className="d-flex flex-column align-items-center justify-content-between p-3 p-sm-4 h-100">
                    <Icon image={service?.icon} alt="" />
                    <span className="d-flex align-items-center text-center mt-sm-4">
                      {service?.link?.title}
                    </span>
                  </Service>
                </NavLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperContainer>
      )}
    </section>
  )
}

export default Services
