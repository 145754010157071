/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { Swiper, SwiperSlide } from 'swiper/react'

// Components
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Interface
import { ServicesProps } from 'components/flex/Services/Shell'

const ServicesWrapper = styled.div`
  margin-top: -6rem;

  .swiper-slide {
    height: auto !important;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
    }
  }
`

const Service = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 20px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.3));
  transition: all 0.2s ease-in-out;
  overflow: visible !important;

  & span {
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 16px;
    line-height: 18px;
  }

  &:hover {
    filter: unset;
  }
`

const Icon = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 90px;
    height: 90px;
  }
  @media (max-width: 991px) {
    width: 70px;
    height: 70px;
  }
`

const OtherServices: React.FC<ServicesProps> = ({ fields, location }) => (
  <section className="mb-5 py-5">
    <div className="container py-5">
      <ServicesWrapper className="row">
        <Content content={fields.description} className="mb-5" />
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 1.5,
            },
            520: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 4,
            },
          }}
          spaceBetween={20}
          className="overflow-visible"
        >
          {fields.services
            ?.filter(
              (service) =>
                !location.pathname.includes(
                  // @ts-ignore
                  service?.link?.title?.toLowerCase()
                )
            )
            .map((service, index) => (
              <SwiperSlide key={index}>
                <NavLink to={service?.link?.url || '#'}>
                  <Service
                    key={index}
                    className="d-flex flex-column align-items-center justify-content-between p-4"
                  >
                    <Icon image={service?.icon} alt="" className="mb-3" />
                    <span className="text-center mt-lg-4">
                      {service?.link?.title}
                    </span>
                  </Service>
                </NavLink>
              </SwiperSlide>
            ))}
        </Swiper>
      </ServicesWrapper>
    </div>
  </section>
)

export default OtherServices
